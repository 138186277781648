<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <h1 class="column cardSize is-two-thirds box p-5 pb-6 is-size-4">
          404: Page Not Found
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "not-found-view",
};
</script>
